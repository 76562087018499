import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetBranches } from "src/api/generic";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddStockCountMutation } from "src/api/inventory/counts";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import moment from "moment";

interface IModel {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch?: Function;
}

const schema = yup.object().shape({
  branch_id: yup.number().required("required"),
  date: yup.string().required("required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const SelectCountBranch = (props: IModel) => {
  const { open, handleClose, row, refetch = () => {} } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { data: branchesData } = useGetBranches();
  const {
    mutate,
    status,
    error,
    isPending,
    data: addResponseData,
  } = useAddStockCountMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const selectedBranch = branchesData?.data.find((branch) => {
    return branch.id === +watch("branch_id");
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { branch_id, date } = data;

    // Lock date validation
    if (
      !!selectedBranch?.close_date &&
      moment(watch("date")).isSameOrBefore(moment(selectedBranch.close_date))
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${generalT("canNotAddEditOrDeleteBeforeLockDate")}: ${
            selectedBranch?.close_date
          }`,
        },
      ]);
      return;
    }

    mutate({
      status: "draft",
      branch_id: branch_id,
      date: date,
    });
  };

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      navigate(`/counts/${addResponseData?.data.id}/edit`);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("addStocktaking")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormControl fullWidth size="small" error={!!errors.branch_id}>
              <InputLabel id="demo-simple-select-label">
                {generalT("branch")}
              </InputLabel>
              <Controller
                name="branch_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={generalT("branch")}
                  >
                    {branchesData?.data?.map(
                      ({ id, name, active_subscriptions }) => {
                        return (
                          <MenuItem
                            key={id}
                            value={`${id}`}
                            disabled={!active_subscriptions?.package.has_items}
                          >
                            {name}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {generalT(`${errors.branch_id?.message || ""}`)}
              </FormHelperText>
            </FormControl>
            <Controller
              name={`date`}
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  variant="outlined"
                  {...field}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  size="small"
                  label={t("date")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Stack>
          <Stack mt={3} spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isPending}
            >
              {generalT("submit")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default SelectCountBranch;
