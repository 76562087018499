import { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useGetBranches } from "src/api/generic";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { convertObjectToFormData } from "src/utils";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import {
  useAddTransferMutation,
  useGetTransferById,
  useUpdateTransferMutation,
} from "src/api/inventory/transfer";
import { useGetLevelsList } from "src/api/inventory/levels";

const schema = yup.object().shape({
  from: yup.string().required("required"),
  to: yup.string().required("required"),
  note: yup.string(),
  items: yup
    .array()
    .of(
      yup.object().shape({
        item_id: yup.number().required("required"),
        item_name: yup.string().required("required"),
        item_sku: yup.string().required("required"),
        item_qnty: yup
          .string()
          .required("required")
          .test("itemQntyMax", "Cannot exceed item level", function (value) {
            const { item_level } = this.parent; // Access sibling fields
            return (
              !isNaN(+value) &&
              !isNaN(item_level) &&
              Number(value) <= Number(item_level)
            );
          }),
        item_level: yup.string().required("required"),
        unit: yup.string().required("required"),
        unit_cost: yup.string().required("required"),
      })
    )
    .required("required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  row?: any;
}

const AddEditTransfer = (props: IProps) => {
  const { open, handleClose, row, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const { t: purchasingT } = useTranslation("purchasing");
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [file, setFile] = useState<any>();
  const [toSend, setToSend] = useState<boolean>(false);
  const isResetting = useRef(false);
  const mode = !row ? "add" : "edit";

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      note: "",
    },
  });

  // APIS
  const { data: branchesData } = useGetBranches();
  const { data: itemsData } = useGetLevelsList({
    branch_id: +watch("from"),
  });
  const { mutate, status, error, isPending } = useAddTransferMutation();
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateTransferMutation();
  const { data, isLoading } = useGetTransferById({
    id: row?.id || "",
    branch_id: row?.from_branch?.id,
  });

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  // Handler function when item is selected
  const handleItemSelect = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedItem(newValue);
    if (newValue) {
      append({
        item_id: newValue.id,
        item_name: newValue.name,
        item_sku: newValue.sku,
        item_qnty: "",
        item_level: newValue.quantity,
        unit: newValue.unit.name,
        unit_cost: newValue.unit_cost,
      });
      setInputValue("");
      setSelectedItem(null);
    }
  };

  // Handle manual changes to the input field
  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    if (selectedItem === null) {
      setInputValue(newInputValue);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { from, to, note, items } = data;

    if (items.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: purchasingT("addItems"),
        },
      ]);
      return;
    }

    const dataToSend = {
      status: toSend ? "sent" : "draft",
      from_branch_id: +from,
      to_branch_id: +to,
      note: note,
    };

    const formDataToSend = convertObjectToFormData(dataToSend);

    // append files
    if (!!file) {
      formDataToSend.append(`files[0]`, file);
    }

    console.log({ dataToSend, toSend });
    if (mode === "add") {
      // append items
      if (items.length > 0) {
        items.forEach((item, index: number) => {
          formDataToSend.append(`items[${index}][item_id]`, `${item.item_id}`);
          formDataToSend.append(
            `items[${index}][quantity]`,
            `${item.item_qnty}`
          );
          formDataToSend.append(
            `items[${index}][unit_cost]`,
            `${item.unit_cost}`
          );
        });
      }

      mutate(formDataToSend);
    } else {
      // Handle edit
      mutateUpdate({
        id: row.id,
        ...dataToSend,
        items: items.map((item) => {
          return {
            item_id: item.item_id,
            quantity: +item.item_qnty,
            unit_cost: +item.unit_cost,
          };
        }),
      });
    }
  };

  useEffect(() => {
    if (!isResetting.current && !!watch("from")) {
      setValue("items", []);
    }
  }, [watch("from")]);

  useEffect(() => {
    if (!!data) {
      isResetting.current = true;
      reset({
        from: data.data.from_branch.id,
        to: data.data.to_branch.id,
        note: data.data.note || "",
        items: data.data.transfer_items.map((item: any) => {
          const itemLevelQuantity = item.item.quantity;
          return {
            item_id: item.item?.id,
            item_name: item.item?.name,
            item_sku: item.item?.sku,
            unit: item.item?.unit?.name,
            item_qnty: item.quantity,
            item_level: itemLevelQuantity,
            unit_cost: item.item.unit_cost,
          };
        }),
      });
      setTimeout(() => {
        isResetting.current = false;
      }, 0);
    }
  }, [data]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      // reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  const selectedItemsIds = watch("items")?.map((item) => item.item_id);

  console.log({ selectedItemsIds, errors });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "95%", md: "800px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "calc(90vh - 48px)", // Deduct padding and header height
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {t("addNewTransaction")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {isLoading ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"400px"}
            pb={"50px"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Stack
                direction={"row"}
                spacing={"10px"}
                alignItems={"flex-start"}
              >
                {/* from */}
                <FormControl fullWidth size="small" error={!!errors.from}>
                  <InputLabel id="demo-simple-select-label">
                    {t("from")}
                  </InputLabel>
                  <Controller
                    name="from"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("from")}
                      >
                        {branchesData?.data.map((branch: any) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {generalT(errors.from?.message || "")}
                  </FormHelperText>
                </FormControl>
                {/* to */}
                <FormControl fullWidth size="small" error={!!errors.to}>
                  <InputLabel id="demo-simple-select-label">
                    {t("to")}
                  </InputLabel>
                  <Controller
                    name="to"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("to")}
                      >
                        {branchesData?.data.map((branch: any) => (
                          <MenuItem key={branch.id} value={branch.id}>
                            {branch.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {generalT(errors.to?.message || "")}
                  </FormHelperText>
                </FormControl>
              </Stack>
              <Typography fontWeight={600} lineHeight={"16.8px"}>
                {t("items")}
              </Typography>

              {fields.map((itemField, index) => {
                return (
                  <Stack
                    key={itemField.id}
                    padding={"8px 16px"}
                    borderRadius={1.5}
                    border={"1px solid #F0F0F0"}
                    direction={"row"}
                    spacing={"10px"}
                    alignItems={"center"}
                    flexWrap="wrap"
                  >
                    {/* item_name */}
                    <Stack spacing={0.2} paddingX={2} width={"100px"}>
                      <Typography
                        fontWeight={400}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {t("name")}
                      </Typography>
                      <Typography
                        fontWeight={700}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {itemField.item_name}
                      </Typography>
                    </Stack>

                    <Stack spacing={0.2} paddingX={2} width={"90px"}>
                      <Typography
                        fontWeight={400}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {t("sku")}
                      </Typography>
                      <Typography
                        fontWeight={700}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {itemField.item_sku}
                      </Typography>
                    </Stack>
                    {/* item_qnty */}
                    <Box minWidth={141}>
                      <Controller
                        name={`items.${index}.item_qnty`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            variant="outlined"
                            {...field}
                            error={!!errors.items?.[index]?.item_qnty}
                            helperText={
                              errors.items?.[index]?.item_qnty?.type ===
                              "itemQntyMax"
                                ? t("cannotExceedItemLevel")
                                : generalT(
                                    errors.items?.[index]?.item_qnty?.message ||
                                      ""
                                  )
                            }
                            size="small"
                            fullWidth
                            sx={{ width: "200px" }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {itemField.unit}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                    {/* <Typography
                    fontWeight={700}
                    lineHeight={"24px"}
                    color={"#292D32"}
                    fontSize={"14px"}
                  >
                    {itemField.unit}
                  </Typography> */}
                    <Stack spacing={0.2} paddingX={3}>
                      <Typography
                        fontWeight={400}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {t("level")}
                      </Typography>
                      <Typography
                        fontWeight={700}
                        color={"#667085"}
                        lineHeight={"24px"}
                        fontSize={"14px"}
                      >
                        {itemField.item_level}
                      </Typography>
                    </Stack>

                    {/* remove item */}
                    <Box
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                      sx={{ flexGrow: 1 }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                          width={"16px"}
                          style={{ cursor: "pointer" }}
                        />
                      </IconButton>
                    </Box>
                  </Stack>
                );
              })}
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={
                  itemsData?.data.filter(
                    (item: any) => !selectedItemsIds?.includes(item.id)
                  ) || []
                }
                sx={{ width: 255 }}
                getOptionLabel={(option: any) => option.name}
                value={selectedItem}
                onChange={handleItemSelect}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderInput={(params: any) => (
                  <TextField {...params} label={t("searchAndAddItem")} />
                )}
              />

              {mode === "add" ? (
                <Box>
                  <FilePondUploader
                    onUpload={(e) => {
                      setFile(e[0]);
                    }}
                    maxFiles={1}
                    maxFileSize={3}
                    acceptedFileTypes={[
                      "image/*",
                      "application/pdf",
                      "text/csv",
                      "application/vnd.ms-excel",
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                  />
                </Box>
              ) : null}

              {/* note */}
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={purchasingT("notesOptional")}
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    maxRows={4}
                    {...field}
                    error={!!errors.note}
                    helperText={errors.note?.message}
                    sx={{ mt: 1 }}
                  />
                )}
              />
              <Stack direction={"row"} spacing={1}>
                <Button
                  variant="outlined"
                  color="tertiary"
                  fullWidth
                  onClick={handleClose}
                >
                  {generalT("cancel")}
                </Button>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={isPending || isPendingUpdate}
                >
                  {generalT("save")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    setToSend(true);
                  }}
                  type="submit"
                  loading={isPending || isPendingUpdate}
                >
                  {generalT("send")}
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        )}
      </Stack>
    </Modal>
  );
};

export default AddEditTransfer;
