import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Chip,
  TablePagination,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import TableActions from "./table-actions";
import TableHeadText from "./table-head-text";
import {
  useDownloadOCList,
  useGetOCTransactionsList,
} from "src/api/operation-cost/transactions";
import { useTranslation } from "react-i18next";
import { downloadFile, formatNumber } from "src/utils";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import AddEditTransactionPopup from "../add-edit-transaction-popup";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterDrawer from "../filters";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";
import { LoadingButton } from "@mui/lab";

const TransactionListTable = () => {
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [refNum, setRefNum] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [paymentId, setPaymentId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [type, setType] = useState("all");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });

  const HeadText = [
    generalT("reference"),
    generalT("branch"),
    generalT("accountName"),
    t("type"),
    t("subTotal"),
    generalT("tax"),
    generalT("totalWithTax"),
    t("paymentMethod"),
    t("paymentDate"),
    t("invoiceDate"),
    t("dueDate"),
    t("remindAt"),
    generalT("createdBy"),
    generalT("createdAt"),
    t("status"),
    generalT("actions"),
  ];

  const filters = {
    ...(!!refNum && { reference_number: refNum }),
    ...(!!type && type !== "all" && { type: type }),
    ...(!!filterStatus && filterStatus !== "all" && { status: filterStatus }),
    ...(!!paymentId && { payment_method_id: +paymentId }),
    ...(!!accountId && { account_id: accountId }),
    due_start_date: dateRange.startDate || undefined,
    due_end_date: dateRange.endDate || undefined,
  };

  // APIS
  const { data, isLoading, refetch, isFetching } = useGetOCTransactionsList({
    page: pageNumber + 1,
    ...filters,
  });
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadOCList({
    ...filters,
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (!openFilter) {
      setPageNumber(0);
      refetch();
    }
  }, [openFilter]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [dateRange.endDate, dateRange.startDate]);

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "operation-cost");
    }
  }, [isFetchingDownload]);

  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("costList")}
        </Typography>
        {/* <Header refetch={refetch} /> */}
        <Stack direction={"row"} gap={1}>
          <DateRangeInput
            startDate={dateRange.startDate ? moment(dateRange.startDate) : null} // moment.Moment | null
            startDateId={`your_unique_start_date_id`}
            endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
            endDateId={`your_unique_end_date_id`}
            onDatesChange={(arg: {
              startDate: moment.Moment | null;
              endDate: moment.Moment | null;
            }) => {
              setDateRange({
                startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                endDate: arg.endDate?.format("yyyy-MM-DD") as string,
              });
            }}
            showClearDates
          />
          <Button
            color="tertiary"
            variant="outlined"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            sx={{ height: "40px" }}
            onClick={handleOpenFilter}
          >
            {generalT("filter")}
          </Button>
          <Box sx={{ minWidth: 114 }}>
            <LoadingButton
              color="tertiary"
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
              loading={isFetchingDownload}
              onClick={handleDownload}
            >
              {generalT("download")}
            </LoadingButton>
          </Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            size="small"
            sx={{ height: "40px" }}
            onClick={handleOpen}
          >
            {t("addNewCost")}
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => {
                return (
                  <TableCell sx={{ bgcolor: "#F9FAFB" }}>
                    <TableHeadText text={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={15} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => {
                const isDeprecationInvoice =
                  !!row?.parent_invoice && row?.account.type === "asset";
                return (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#F9FAFB",
                      },
                    }}
                    // onClick={navigateToOperationCost}
                  >
                    <TableCell>{row.reference}</TableCell>
                    <TableCell>{row.branch.name}</TableCell>
                    <TableCell>{row.account?.name}</TableCell>
                    <TableCell>
                      {row.account?.type === "variable"
                        ? t("variable")
                        : row.account?.type === "asset"
                        ? t("asset")
                        : t("fixedCost")}
                    </TableCell>
                    <TableCell>{formatNumber(row.amount)}</TableCell>
                    <TableCell>{formatNumber(row.tax)}</TableCell>
                    <TableCell>{formatNumber(row.total)}</TableCell>
                    <TableCell>
                      {row.payments?.map((pay: any, index: number) => {
                        return `${pay.payment_method.name}${
                          index < row.payments.length - 1 ? ", " : ""
                        }`;
                      })}
                    </TableCell>
                    <TableCell>{row.payment_date || "--"}</TableCell>
                    <TableCell>{row.invoice_date}</TableCell>
                    <TableCell>{row.due_date}</TableCell>
                    <TableCell>{row.repeat_date || ""}</TableCell>
                    <TableCell>
                      {row.created_by?.name}{" "}
                      {row.added_by === "SYS" ? "(SYS)" : ""}
                    </TableCell>
                    <TableCell dir="ltr">{row.created_at}</TableCell>
                    <TableCell align="center">
                      <Chip
                        label={
                          isDeprecationInvoice
                            ? t("deprecation")
                            : row.status === "Paid"
                            ? t("paid")
                            : row.status === "Partly Paid"
                            ? t("partiallyPaid")
                            : row.status === "UnPaid"
                            ? t("unpaid")
                            : row.status === "Reminder"
                            ? t("reminder")
                            : t("overpaid")
                        }
                        color={
                          isDeprecationInvoice
                            ? "info"
                            : row.status === "Paid"
                            ? "success"
                            : row.status === "Partly Paid" ||
                              row.status === "Reminder"
                            ? "warning"
                            : "error"
                        }
                        variant="outlined"
                        sx={{ height: "22px" }}
                      />
                    </TableCell>

                    <TableCell>
                      <TableActions
                        row={row}
                        refetch={refetch}
                        isDeprecationInvoice={isDeprecationInvoice}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 2 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      <FilterDrawer
        open={openFilter}
        refNum={refNum}
        setRefNum={setRefNum}
        status={filterStatus}
        setStatus={setFilterStatus}
        paymentId={paymentId}
        setPaymentId={setPaymentId}
        accountId={accountId}
        setAccountId={setAccountId}
        handleClose={handleCloseFilter}
        type={type}
        setType={setType}
      />
      {open && (
        <AddEditTransactionPopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
export default TransactionListTable;
