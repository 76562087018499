import { Stack, Typography } from "@mui/material";
interface IProps {
  label: string;
  value: string;
}
const InfoItem = (props: IProps) => {
  const { label, value } = props;
  return (
    <Stack direction={"row"}>
      <Typography
        fontSize={"14px"}
        color={"#344054"}
        fontWeight={700}
        lineHeight={"18.8px"}
      >
        {label}:
        <Typography
          component={"span"}
          fontWeight={400}
          fontSize={"14px"}
          lineHeight={"18.8px"}
        >
          {" "}
          {value}
        </Typography>
      </Typography>
    </Stack>
  );
};

export default InfoItem;
