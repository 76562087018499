import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  status?: string;
}

export const useGetAdjustmentsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-Adjustments-list", params.page, params.status],
    queryFn: async () => {
      return apiClient.get(`/api/adjustments`, { params });
    },
  });

export const useAddAdjustments = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/adjustments`, data);
    },
  });

export const useImportAdjustmentsMutation = () =>
  useMutation<any, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/adjustments/import`, data);
    },
  });

export const useGetAdjustmentById = ({
  id,
  enabled,
}: {
  id: string;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-adjustments-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/adjustments/${id}`);
    },
    gcTime: 0,
    enabled,
  });
