import { Box, Button, Drawer, Stack, TextField } from "@mui/material";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface IProps {
  open: boolean;
  handleClose: any;
  name: string;
  setName: Function;
  sku: string;
  setSku: Function;
}

const FilterDrawer = (props: IProps) => {
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const { open, handleClose, name, setName, sku, setSku } = props;
  const [tempName, setTempName] = useState(name);
  const [tempSku, setTempSku] = useState(sku);

  const handleApply = () => {
    handleClose();
    setName(tempName);
    setSku(tempSku);
  };

  const handleClearFilters = () => {
    handleClose();
    setName("");
    setSku("");

    setTempName("");
    setTempSku("");
  };

  const Types = [
    {
      label: "all",
      value: "all",
    },
    {
      label: "direct",
      value: "direct",
    },
    {
      label: "nonDc",
      value: "indirect",
    },
  ];

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {t("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          {/* name */}
          <TextField
            label={t("name")}
            variant="outlined"
            size="small"
            fullWidth
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
          />
          {/* sku */}
          <TextField
            label={t("SKU")}
            variant="outlined"
            size="small"
            fullWidth
            value={tempSku}
            onChange={(e) => setTempSku(e.target.value)}
          />
          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleApply}>
            {generalT("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {generalT("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
