import { Box, Chip, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import WarningIcon from "src/assets/svg-icons/auditing/warning.svg";
import SuccessIcon from "src/assets/svg-icons/auditing/success.svg";
import ErrorIcon from "src/assets/svg-icons/auditing/error.svg";

interface IProps {
  variant?: "info" | "success" | "warning" | "error";
  label?: string;
}

const SecondaryChip = (props: IProps) => {
  const { t } = useTranslation("auditing");
  const { variant = "info", label } = props;

  const defaultLabel =
    variant === "warning"
      ? t("progress")
      : variant === "success"
      ? t("confirmed")
      : variant === "error"
      ? t("issue")
      : t("new");

  const labelToView = label ? label : defaultLabel;

  return (
    <Stack>
      <Chip
        sx={{
          color:
            variant === "warning"
              ? "#FDB022"
              : variant === "success"
              ? "#17B26A"
              : variant === "error"
              ? "#B32318"
              : "#4D4DEC",
          backgroundColor:
            variant === "warning"
              ? "#FFFAEB"
              : variant === "success"
              ? "#ECFDF3"
              : variant === "error"
              ? "#FEF3F2"
              : "F2F2FD",
          border:
            variant === "warning"
              ? "1px solid #FEDF89"
              : variant === "success"
              ? "1px solid #A9EFC5"
              : variant === "error"
              ? "1px solid #FDA29B"
              : "1px solid #9999F4",
          padding: "4px 10px ",
          "& .MuiChip-label": {
            fontSize: "14px",
            fontWeight: 500,
          },
        }}
        label={labelToView}
        icon={
          variant === "warning" ? (
            <img src={WarningIcon} alt="" />
          ) : variant === "success" ? (
            <img src={SuccessIcon} alt="" />
          ) : variant === "error" ? (
            <img src={ErrorIcon} alt="" />
          ) : (
            <Box
              bgcolor={"#4D4DEC"}
              height={"10px"}
              width={"10px"}
              borderRadius={"50%"}
            />
          )
        }
      />
    </Stack>
  );
};

export default SecondaryChip;
