import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InventoryIcon from "src/assets/svg-icons/side-bar/inventory.svg";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useGetItemsList } from "src/api/purchasing/items";
import InfoItem from "./info-item";
import {
  useAddAdjustments,
  useGetAdjustmentById,
} from "src/api/inventory/adjustments";
import { useGetBranches } from "src/api/generic";
import { useGetLevelsList } from "src/api/inventory/levels";

const schema = yup.object().shape({
  branch_id: yup.string().required("required"),
  date: yup.string().required("required"),
  items: yup
    .array()
    .of(
      yup.object().shape({
        item_id: yup.number().required("required"),
        item_name: yup.string().required("required"),
        item_sku: yup.string().required("required"),
        unit_cost: yup.string().required("required"),
        old_unit_cost: yup.string().required("required"),
      })
    )
    .required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddAdjustments = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("inventory");
  const { t: purchasingT } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const mode = !id ? "add" : "view";

  const navigateToInventory = () => {
    navigate("/inventory/items");
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // APIs
  const {
    data: adjustmentsData,
    status: getAdjustmentByIdStatus,
    refetch,
    isFetching,
  } = useGetAdjustmentById({
    enabled: !!id,
    id: id as string,
  });
  const { data: branchesData } = useGetBranches();
  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });
  const { mutate, status, error, isPending } = useAddAdjustments();

  // const { data: itemsData, isLoading } = useGetItemsList();
  const { data: itemsData } = useGetLevelsList({
    branch_id: +watch("branch_id"),
  });

  // Handler function when item is selected
  const handleItemSelect = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedItem(newValue);
    if (newValue) {
      append({
        item_id: newValue.id,
        item_name: newValue.name,
        item_sku: newValue.sku,
        unit_cost: "",
        old_unit_cost: newValue.unit_cost,
      });
      setInputValue("");
      setSelectedItem(null);
    }
  };

  // Handle manual changes to the input field
  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    if (selectedItem === null) {
      setInputValue(newInputValue);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log(data);
    const { branch_id, items, date } = data;

    if (items.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: purchasingT("addItems"),
        },
      ]);
      return;
    }

    const transformedItems = items.map((item) => ({
      item_id: item.item_id,
      unit_cost: item.unit_cost,
    }));

    const dataToSend = {
      branch_id: branch_id,
      items: transformedItems,
      date,
    };

    mutate(dataToSend);
  };
  const selectedItemsIds = watch("items")?.map((item) => item.item_id);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      navigateToInventory();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  useEffect(() => {
    if (getAdjustmentByIdStatus === "success") {
      reset({
        branch_id: adjustmentsData.data.branch.id,
        date: adjustmentsData.data.date,
        items: adjustmentsData.data.adjustment_items.map((item: any) => {
          return {
            item_id: item.item?.id,
            item_name: item.item?.name,
            item_sku: item.item?.sku,
            unit_cost: item?.unit_cost,
          };
        }),
      });
    }
  }, [getAdjustmentByIdStatus, isFetching]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={InventoryIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t(mode === "add" ? "addAdjustments" : "viewAdjustments")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      {isFetching ? (
        <Box display={"flex"} justifyContent={"center"} pt={20}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack spacing={2} direction={"row"} alignItems={"center"}>
            <Typography
              color={"#475467"}
              lineHeight={"19.2px"}
              onClick={navigateToInventory}
              sx={{ cursor: "pointer" }}
            >
              {t("inventory")}
            </Typography>
            <img src={RightArrow} alt="" />
            <Typography
              color={"#475467"}
              lineHeight={"19.2px"}
              sx={{ cursor: "pointer" }}
            >
              {t(mode === "add" ? "addAdjustments" : "viewAdjustments")}
            </Typography>

            <img src={RightArrow} alt="" />
            <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
              <Typography
                color={"#344054"}
                fontWeight={600}
                lineHeight={"19.2px"}
              >
                {mode === "add"
                  ? generalT("add")
                  : adjustmentsData?.data.reference}
              </Typography>
            </Box>
          </Stack>
          {adjustmentsData && (
            <Stack bgcolor={"#F9FAFB"} spacing={1.5} p={2}>
              <InfoItem
                label={purchasingT("branch")}
                value={adjustmentsData.data.branch.name}
              />
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <InfoItem
                    label={generalT("createdAt")}
                    value={adjustmentsData.data.created_at}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <InfoItem
                    label={generalT("createdBy")}
                    value={adjustmentsData.data.created_by?.name}
                  />
                </Grid>
              </Grid>
            </Stack>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* from */}
            <Stack
              maxWidth={555}
              gap={1.5}
              direction={"row"}
              flexWrap={{ xs: "wrap", sm: "nowrap" }}
            >
              <FormControl fullWidth size="small" error={!!errors.branch_id}>
                <InputLabel id="demo-simple-select-label">
                  {generalT("selectBranch")}
                </InputLabel>
                <Controller
                  name="branch_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={generalT("selectBranch")}
                      disabled={mode === "view"}
                    >
                      {branchesData?.data.map((branch: any) => (
                        <MenuItem key={branch.id} value={branch.id}>
                          {branch.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {generalT(errors.branch_id?.message || "")}
                </FormHelperText>
              </FormControl>

              <Controller
                name={`date`}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="date"
                    variant="outlined"
                    {...field}
                    error={!!errors.date}
                    helperText={errors.date?.message}
                    size="small"
                    label={t("date")}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={mode === "view"}
                  />
                )}
              />
            </Stack>

            <Stack direction={"column"} spacing={2} marginTop={2}>
              <Typography fontWeight={600} lineHeight={"16.8px"}>
                {t("items")}
              </Typography>
              <Stack
                direction={"column"}
                spacing={2}
                marginTop={2}
                minHeight={"250px"}
              >
                {fields.map((itemField, index) => {
                  return (
                    <Stack
                      key={itemField.id}
                      padding={"8px 16px"}
                      borderRadius={1.5}
                      border={"1px solid #F0F0F0"}
                      direction={"row"}
                      spacing={"10px"}
                      alignItems={"center"}
                      flexWrap="wrap"
                    >
                      {/* item_name */}
                      <Stack spacing={0.2} paddingX={2} width={"200px"}>
                        <Typography
                          fontWeight={400}
                          color={"#667085"}
                          lineHeight={"24px"}
                          fontSize={"14px"}
                        >
                          {t("name")}
                        </Typography>
                        <Typography
                          fontWeight={700}
                          color={"#667085"}
                          lineHeight={"24px"}
                          fontSize={"14px"}
                        >
                          {itemField.item_name}
                        </Typography>
                      </Stack>

                      <Stack spacing={0.2} paddingX={2} width={"90px"}>
                        <Typography
                          fontWeight={400}
                          color={"#667085"}
                          lineHeight={"24px"}
                          fontSize={"14px"}
                        >
                          {t("sku")}
                        </Typography>
                        <Typography
                          fontWeight={700}
                          color={"#667085"}
                          lineHeight={"24px"}
                          fontSize={"14px"}
                        >
                          {itemField.item_sku}
                        </Typography>
                      </Stack>

                      {mode === "add" && (
                        <Stack spacing={0.2} paddingX={2} width={"110px"}>
                          <Typography
                            fontWeight={400}
                            color={"#667085"}
                            lineHeight={"24px"}
                            fontSize={"14px"}
                          >
                            {t("unitCost")}
                          </Typography>
                          <Typography
                            fontWeight={700}
                            color={"#667085"}
                            lineHeight={"24px"}
                            fontSize={"14px"}
                          >
                            {itemField.old_unit_cost}
                          </Typography>
                        </Stack>
                      )}

                      {/*  */}
                      <Box minWidth={141}>
                        <Controller
                          name={`items.${index}.unit_cost`}
                          control={control}
                          render={({ field }) => (
                            <TextField
                              variant="outlined"
                              {...field}
                              error={!!errors.items?.[index]?.unit_cost}
                              helperText={
                                errors.items?.[index]?.unit_cost?.type
                              }
                              size="small"
                              fullWidth
                              label={t("newUnitCost")}
                              disabled={mode === "view"}
                            />
                          )}
                        />
                      </Box>

                      {/* remove item */}
                      {mode === "add" ? (
                        <Box
                          display={"flex"}
                          justifyContent={"flex-end"}
                          alignItems={"center"}
                          sx={{ flexGrow: 1 }}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <img
                              src={DeleteIcon}
                              alt=""
                              width={"16px"}
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                        </Box>
                      ) : null}
                    </Stack>
                  );
                })}
                {mode === "add" && (
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={
                      itemsData?.data.filter(
                        (item: any) => !selectedItemsIds?.includes(item.id)
                      ) || []
                    }
                    sx={{ width: 255, mt: 2 }}
                    getOptionLabel={(option: any) => option.name}
                    value={selectedItem}
                    onChange={handleItemSelect}
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    renderInput={(params: any) => (
                      <TextField {...params} label={t("searchAndAddItem")} />
                    )}
                  />
                )}
              </Stack>

              {mode === "add" ? (
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    width={{ xs: "100%", md: "30%" }}
                  >
                    <Button
                      variant="outlined"
                      color="tertiary"
                      onClick={navigateToInventory}
                      fullWidth
                    >
                      {generalT("cancel")}
                    </Button>

                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isPending}
                      fullWidth
                    >
                      {generalT("save")}
                    </LoadingButton>
                  </Stack>
                </Box>
              ) : null}
            </Stack>
          </form>
        </>
      )}
    </Stack>
  );
};

export default AddAdjustments;
