import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import TotalSales from "./total-sales";
import AvgOrder from "./avg-order";
import CostChart from "./cost-chart";
import ProductsStatistics from "./products-statistics";
import moment from "moment";
import { useGetPaymentMethods } from "src/api/generic";
import DateRangeInput from "src/shared/components/date-range";
import {
  useGetAggCostChartData,
  useGetAggregatorsTotalSales,
  useGetAverageOrderData,
  useGetHighProfitProducts,
  useGetLowProfitProducts,
  useGetPopularProfitProducts,
} from "src/api/aggreagators";
import Lottie from "react-lottie";
import * as animationData from "src/assets/animated/loader.json";
import { useTranslation } from "react-i18next";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface IProps {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  setDateRange: Function;
}

const Summary = ({ dateRange, setDateRange }: IProps) => {
  const [payments, setPayments] = useState<number[]>([]);
  const [tempSelectedPayments, setTempSelectedPayments] = useState<number[]>(
    []
  );

  const [endDateFocused, setEndDateFocused] = useState(false);

  const { data: paymentsData } = useGetPaymentMethods();
  const { t } = useTranslation("aggregators");

  const {
    data: dataTotalSales,
    refetch: refetchTotalSales,
    isFetching: isFetchingTotalSales,
  } = useGetAggregatorsTotalSales({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: dataAvgOrder,
    refetch: refetchAvgOrder,
    isFetching: isFetchingAvgOrder,
  } = useGetAverageOrderData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: dataHighProfitProducts,
    refetch: refetchHighProfitProducts,
    isFetching: isFetchingHighProfitProducts,
  } = useGetHighProfitProducts({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: dataLowProfitProducts,
    refetch: refetchLowProfitProducts,
    isFetching: isFetchingLowProfitProducts,
  } = useGetLowProfitProducts({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: dataPopularProfitProducts,
    refetch: refetchPopularProfitProducts,
    isFetching: isFetchingPopularProfitProducts,
  } = useGetPopularProfitProducts({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const {
    data: dataAggCostChart,
    refetch: refetchAggCostChart,
    isFetching: isFetchingAggCostChart,
  } = useGetAggCostChartData({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
    ...(payments.length > 0 && { payment_methods: payments }),
  });

  const isFetchingData =
    isFetchingTotalSales ||
    isFetchingAvgOrder ||
    isFetchingHighProfitProducts ||
    isFetchingLowProfitProducts ||
    isFetchingPopularProfitProducts;

  useEffect(() => {
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = dateRange.endDate ? moment(dateRange.endDate) : null;
    if (startDate && endDate && endDate.diff(startDate, "days") < 31) {
      refetchTotalSales();
      refetchAvgOrder();
      refetchHighProfitProducts();
      refetchLowProfitProducts();
      refetchPopularProfitProducts();
      refetchAggCostChart();
    }
  }, [payments, dateRange.endDate, dateRange.startDate]);

  const handleClose = () => {
    setPayments(tempSelectedPayments);
  };

  const handleChangePayments = (event: any) => {
    const {
      target: { value },
    } = event;
    setTempSelectedPayments(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  return (
    <Stack gap={3} pb={4}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          mb={{ xs: "24px", md: 0 }}
        >
          {t("aggSummary")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/* select payments */}
          <Box sx={{ minWidth: 207, maxWidth: { xs: "90vw", sm: 207 } }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-multiple-checkbox-label">
                {t("payments")}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={tempSelectedPayments}
                onChange={handleChangePayments}
                onClose={handleClose}
                input={<OutlinedInput label={t("payments")} />}
                renderValue={(selected) =>
                  selected
                    .map(
                      (selectedId: number) =>
                        paymentsData?.data.find(
                          (branch: any) => branch.id === +selectedId
                        )?.name
                    )
                    .join(", ")
                }
              >
                {paymentsData?.data.map((branch) => (
                  <MenuItem
                    key={branch.id}
                    value={branch.id}
                    sx={{ padding: "0" }}
                  >
                    <Checkbox
                      size="small"
                      checked={tempSelectedPayments.indexOf(branch.id) > -1}
                    />
                    <ListItemText primary={branch.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/*  Data Select  */}
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null;
              startDateId={`your_unique_start_date_id`} // moment.Moment | null;
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null;
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>
          <Box sx={{ minWidth: 114 }}>
            <Button
              color="tertiary"
              variant="outlined"
              endIcon={<img src={DownloadIcon} alt="" />}
            >
              {t("download")}
            </Button>
          </Box>
        </Stack>
      </Stack>
      {isFetchingData ? (
        <Box display={"flex"} alignItems={"center"} height={"60vh"}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </Box>
      ) : (
        <>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TotalSales data={dataTotalSales?.data} />
              </Grid>
              <Grid item xs={12} md={6}>
                <AvgOrder data={dataAvgOrder?.data} />
              </Grid>
            </Grid>
          </Box>
          {/*  Products statistics component  */}
          <ProductsStatistics
            highProfitProducts={dataHighProfitProducts?.data}
            lowProfitProducts={dataLowProfitProducts?.data}
            popularProfitProducts={dataPopularProfitProducts?.data}
          />
          <CostChart data={dataAggCostChart?.data} />
        </>
      )}
    </Stack>
  );
};

export default Summary;
