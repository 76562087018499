import React, { useEffect, useState } from "react";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import SalesTable from "./table";
import { useGetSalesList } from "src/api/settings/sales";
import FilterDrawer from "./filters";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddDaysOffPopup from "./add-days-off-popup";
import DateRangeInput from "src/shared/components/date-range";
import moment from "moment";

const Sales = () => {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [branches, setBranches] = useState<number[]>([]);
  const [refNum, setRefNum] = useState("");
  const [type, setType] = useState("all");
  const [open, setOpen] = useState(false);
  const [openDaysOff, setOpenDaysOff] = useState(false);
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });

  const { data, isFetching, refetch } = useGetSalesList({
    page: pageNumber + 1,
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
    ...(!!refNum && { reference_number: refNum }),
    ...(branches.length > 0 && { branches: branches }),
    ...(type === "daysOff" && { show_dayoff: 1 }),
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDaysOff = () => setOpenDaysOff(true);
  const handleCloseDaysOff = () => setOpenDaysOff(false);

  useEffect(() => {
    if (!open) {
      setPageNumber(0);
      refetch();
    }
  }, [open, pageNumber]);

  useEffect(() => {
    if (
      !!dateRange.endDate ||
      (dateRange.endDate === undefined && dateRange.startDate === undefined)
    ) {
      refetch();
    }
  }, [dateRange.endDate, dateRange.startDate]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  return (
    <Stack spacing={1.5}>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("salesList")}
        </Typography>

        <Stack direction={"row"} gap={2}>
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
                setPageNumber(0);
              }}
              showClearDates
            />
          </Box>
          <Button
            color="tertiary"
            variant="outlined"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            sx={{ height: "40px" }}
            onClick={handleOpen}
          >
            {generalT("filter")}
          </Button>
          {/* <Button
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {generalT("download")}
          </Button> */}
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenDaysOff}
          >
            {t("addDaysOff")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/add-sales")}
          >
            {t("addNewSalesJourney")}
          </Button>
        </Stack>
      </Stack>
      <SalesTable rows={data?.data} isLoading={isFetching} refetch={refetch} />
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
      {/* Filter Drawer */}
      <FilterDrawer
        open={open}
        setBranches={setBranches}
        branches={branches}
        refNum={refNum}
        setRefNum={setRefNum}
        handleClose={handleClose}
        type={type}
        setType={setType}
      />
      {/* Days off Popup */}
      {openDaysOff && (
        <AddDaysOffPopup
          open={openDaysOff}
          handleClose={handleCloseDaysOff}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};

export default Sales;
