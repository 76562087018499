import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Chip, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

const UserTable = ({
  users,
  isLoading,
  refetch,
}: {
  users: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = users?.length === 0;
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    t("userName"),
    t("roles"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("status"),
    generalT("actions"),
  ];

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeadText.map((item) => (
              <TableCell
                align="center"
                sx={{
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "18px",
                  color: "#475467",
                  bgcolor: "#F9FAFB",
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading ? (
          <TableLoadingSkeleton rowsLength={8} cellsLength={6} />
        ) : (
          <TableBody>
            {users?.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">
                  {row.branches?.map((branch: any, index: number) =>
                    branch.names.map(
                      (role: string, i: number) =>
                        `${role}${
                          index === row.branches.length - 1 &&
                          i === branch.names.length - 1
                            ? ""
                            : ", "
                        }`
                    )
                  )}
                </TableCell>
                <TableCell align="center">{row?.created_by?.name}</TableCell>
                <TableCell align="center" dir="ltr">{row?.created_at}</TableCell>
                <TableCell align="center">
                  <Chip
                    size="small"
                    color={row.status ? "success" : "error"}
                    label={row.status ? "Active" : "Inactive"}
                  />
                </TableCell>
                <TableCell align="center">
                  <TableActions
                    refetch={refetch}
                    userData={row}
                    type={row.type}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {emptyDataArr && <NoData />}
    </TableContainer>
  );
};
export default UserTable;
