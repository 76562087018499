import { useMutation } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IBranchAPIBody {
  id?: number;
  name?: string;
  city_id?: number;
  vat?: number;
  status?: boolean;
  close_date?: string | null
}

export const useUpdateBranchMutation = () =>
  useMutation<any, APIErrorResponse, IBranchAPIBody>({
    mutationFn: async (data) => {
      const { id, ...restData } = data;
      return apiClient.put(`/api/branches/${id}`, restData);
    },
  });
