import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  status?: string;
}

export const useGetTransfersList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-transfers-list", params.page, params.status],
    queryFn: async () => {
      return apiClient.get(`/api/transfers`, { params });
    },
  });

export const useAddTransferMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/transfers`, data);
    },
  });

export const useUpdateTransferMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/transfers/${id}`, rest);
    },
  });

export const useDeleteTransferMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/transfers/${id}`);
    },
  });

export const useGetTransferById = ({
  id,
  branch_id,
}: {
  id: string;
  branch_id?: number | undefined;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-transfer-trans-by-id", id, branch_id],
    queryFn: async () => {
      return apiClient.get(`/api/transfers/${id}`, { params: { branch_id } });
    },
    enabled: !!id,
  });

export const useDownloadTransferReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["download-transfer-list-report", params.status || ""],
    queryFn: async () => {
      return apiClient.get(`/api/transfers/download`, { params });
    },
    enabled: false,
    gcTime: 0,
  });
