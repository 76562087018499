import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import AddEditTransfer from "../../add-edit-transfer";
import { useDeleteTransferMutation } from "src/api/inventory/transfer";

const TableActions = ({ row, refetch }: { row: any; refetch: Function }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t: generalT } = useTranslation("general");
  const [openEditModal, setOpenEditModal] = useState(false);

  // APIS
  const { mutate, isPending, status, error } = useDeleteTransferMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(row.id);
  };

  const navigateToTransDetails = () => {
    navigate(`/transfer/transaction/${row.id}`);
  };

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"flex-end"}
    >
      {row.status === "draft" && (
        <Tooltip title={generalT("edit")}>
          <IconButton onClick={handleOpenEditModal}>
            <img src={EditIcon} alt="" />
          </IconButton>
        </Tooltip>
      )}

      {row.status !== "draft" && (
        <Tooltip title={generalT("details")}>
          <IconButton onClick={navigateToTransDetails}>
            <VisibilityOutlinedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title={generalT("delete")}>
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      {openEditModal && (
        <AddEditTransfer
          open={openEditModal}
          handleClose={handleCloseEditModal}
          row={row}
          refetch={refetch}
        />
      )}

      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={row.reference}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
    </Stack>
  );
};
export default TableActions;
