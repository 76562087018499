import { Box, Stack, Typography } from "@mui/material";
interface IProps {
  label: string;
  value: string;
  color?: string;
}

const CalcItem = (props: IProps) => {
  const { label, value, color = "#667085" } = props;
  return (
    <Box minWidth={"14%"} width={"13%"}>
      <Typography
        color={"#667085"}
        fontSize={{ xs: "10px", sm: "14px" }}
        lineHeight={"24px"}
      >
        {label}
      </Typography>
      <Typography
        color={color}
        fontSize={{ xs: "10px", sm: "14px" }}
        lineHeight={"24px"}
        fontWeight={700}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default CalcItem;
