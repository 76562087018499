import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddAEditSalesPage from "src/pages/add-sales";
import ForgotPasswordPage from "src/pages/auth/forgot-password";
import DashboardPage from "src/pages/dashboard";
import AggregatorsPage from "src/pages/aggregators";
import LoginPage from "src/pages/auth/login";
import SingUpPage from "src/pages/auth/sing-up";
import { PublicRoutes } from "./public-routes";
import { PrivateRoutes } from "./private-routes";
import ResetPasswordPage from "src/pages/auth/reset-password";
import { Layout } from "src/shared/layout";
import AddEditAggregator from "src/pages/aggregators/tabs/aggregators-list/add-new-aggregator";
import AddEditAggregatorFees from "src/pages/aggregators/tabs/aggregator-fees/add-edit-aggregator-fees";
import Settings from "src/pages/settings";
import AddEditRole from "src/pages/settings/tabs/roles/add-edit-role";
import OperationCost from "src/pages/operation-cost";
import TransactionDetails from "src/pages/operation-cost/tabs/transaction-list/transaction-details";
import MyProfile from "src/pages/my- profile";
import HomePage from "src/pages/home-page";
import PricesPage from "src/pages/prices-page";
import ReportsPages from "src/pages/reports";
import PurchasingTransactionDetails from "src/pages/purchasing/tabs/purchasing/transaction-details";
import Purchasing from "src/pages/purchasing";
import ImportPurchasing from "src/pages/purchasing/tabs/purchasing/import-purchasing";
import Inventory from "src/pages/inventory";
import AddNewStocktaking from "src/pages/inventory/tabs/count/all/add-new-stocktaking";
import AddNewCogs from "src/pages/inventory/tabs/cogs/add-new-cogs";
import Matching from "src/pages/matching";
import Orders from "src/pages/matching/tabs/aggregator/orders";
import AddAggregatorTransactions from "src/pages/matching/tabs/aggregator/Add-aggregator-transactions";
import Auditing from "src/pages/auditing";
import TransferTransactionDetails from "src/pages/inventory/tabs/transfer/transaction-details";
import AddAdjustments from "src/pages/inventory/tabs/items/tabs/adjustments/add-adjustments";

function AllRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route element={<HomePage />} path="/home" />
          <Route element={<LoginPage />} path="/login" />
          <Route element={<SingUpPage />} path="/sign-up" />
          <Route element={<ForgotPasswordPage />} path="/forgot-password" />
          <Route element={<ResetPasswordPage />} path="/reset-password/:id" />
          <Route element={<PricesPage />} path="/prices" />
        </Route>
        <Route element={<PrivateRoutes />}>
          {/* Add sales  */}
          <Route element={<AddAEditSalesPage />} path="/" />
          <Route element={<AddAEditSalesPage />} path="/add-sales" />
          <Route element={<AddAEditSalesPage />} path="/sales/:id/edit" />
          {/* My Profile */}
          <Route element={<MyProfile />} path="/profile" />
          <Route element={<Layout />}>
            {/* Dashboard */}
            <Route element={<DashboardPage />} path="/dashboard" />
            {/* Aggregators */}
            <Route element={<AggregatorsPage />} path="/aggregators" />
            <Route element={<AggregatorsPage />} path="/aggregators/summary" />
            <Route element={<AggregatorsPage />} path="/aggregators/list" />
            <Route
              element={<AggregatorsPage />}
              path="/aggregators/transactions"
            />
            <Route element={<AggregatorsPage />} path="/aggregators/fees" />
            <Route
              element={<AddEditAggregator />}
              path="/aggregators/add-agg"
            />
            <Route
              element={<AddEditAggregator />}
              path="/aggregators/edit-agg/:id"
            />
            <Route
              element={<AddEditAggregatorFees />}
              path="/aggregators/add-agg-fees"
            />
            <Route
              element={<AddEditAggregatorFees />}
              path="/aggregators/edit-agg-fees/:id"
            />
            {/* Third Eye Report */}
            <Route element={<ReportsPages />} path="/reports" />
            {/* Operation Cost */}
            <Route element={<OperationCost />} path="/operation" />
            <Route path="/operation/summary" element={<OperationCost />} />
            <Route path="/operation/accounts" element={<OperationCost />} />
            <Route path="/operation/cost-list" element={<OperationCost />} />
            <Route
              element={<TransactionDetails />}
              path="/operation/transaction/:id"
            />
            {/* Settings */}
            <Route element={<Settings />} path="/settings" />
            <Route element={<Settings />} path="/settings/branches" />
            <Route element={<Settings />} path="/settings/users" />
            <Route element={<Settings />} path="/settings/roles" />
            <Route element={<Settings />} path="/settings/payments" />
            <Route
              element={<Settings />}
              path="/settings/payment-transactions"
            />
            <Route element={<Settings />} path="/settings/sales" />
            <Route element={<Settings />} path="/settings/capital" />
            <Route
              element={<AddEditRole />}
              path="/settings/roles/add-new-role"
            />
            <Route element={<AddEditRole />} path="/settings/roles/:id/edit" />
            {/* purchasing */}
            <Route element={<Purchasing />} path="/purchasing" />
            <Route element={<Purchasing />} path="/purchasing/summary" />
            <Route element={<Purchasing />} path="/purchasing/list" />
            <Route element={<Purchasing />} path="/purchasing/suppliers" />
            <Route element={<Purchasing />} path="/purchasing/events" />
            <Route
              element={<PurchasingTransactionDetails />}
              path="/purchasing/:id"
            />
            <Route element={<ImportPurchasing />} path="/purchasing/import" />
            {/* Inventory */}
            <Route element={<Inventory />} path="/inventory" />
            <Route element={<Inventory />} path="/inventory/summary" />
            <Route element={<Inventory />} path="/inventory/items" />
            <Route element={<Inventory />} path="/inventory/counts" />
            <Route element={<Inventory />} path="/inventory/level" />
            <Route element={<Inventory />} path="/inventory/cogs" />
            <Route
              element={<AddNewStocktaking />}
              path="/:brId/:brName/add-stocktaking"
            />
            <Route element={<AddNewStocktaking />} path="/counts/:id/edit" />
            <Route
              element={<AddNewCogs />}
              path="/inventory/cogs/add-new-cogs"
            />
            <Route element={<AddNewCogs />} path="/inventory/cogs/:id/edit" />
            <Route element={<Inventory />} path="/inventory/transfer" />
            <Route
              element={<TransferTransactionDetails />}
              path="/transfer/transaction/:id"
            />
            <Route
              element={<AddAdjustments />}
              path="/inventory/add-adjustments"
            />
            <Route element={<AddAdjustments />} path="/adjustments/:id/view" />

            {/* Matching */}
            <Route element={<Matching />} path="/matching" />
            {/* <Route element={<Matching />} path="/matching/link-list" /> */}
            <Route element={<Matching />} path="/matching/bank" />
            <Route element={<Matching />} path="/matching/aggregator" />
            <Route element={<Orders />} path="/matching/aggregator/:id" />
            <Route
              element={<AddAggregatorTransactions />}
              path="/matching/add-aggregator-transactions"
            />
            {/* Auditing */}
            <Route element={<Auditing />} path="/auditing" />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AllRoutes;
