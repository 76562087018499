import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import InfoBox from "src/shared/components/info-box";
import { useGetCities } from "src/api/generic";
import { useUpdateBranchMutation } from "src/api/settings/branches";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

interface IModel {
  open: boolean;
  handleClose: () => void;
  row?: any;
  refetch?: Function;
}

const schema = yup.object().shape({
  name: yup.string().required("Branch name is required"),
  city_id: yup.number().required("Branch location is required"),
  vat: yup
    .number()
    .typeError("Vat is required")
    .required("Vat  is required")
    .max(100, "VAT cannot exceed 100"),
  hasCloseDate: yup.boolean().required("Required"),
  closeDate: yup.string().when("hasCloseDate", {
    is: (value: boolean) => value === true,
    then: (value) => value.required("Required"),
  }),
});

interface FormInputs extends yup.InferType<typeof schema> {}

const AddEditBranch = (props: IModel) => {
  const { open, handleClose, row, refetch = () => {} } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [mode, setMode] = useState<"add" | "edit">(!row ? "add" : "edit");
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  const { data: citiesData } = useGetCities();
  const { mutate, status, error, isPending } = useUpdateBranchMutation();

  const { control, handleSubmit, formState, reset, watch } =
    useForm<FormInputs>({
      mode: "onBlur",
      resolver: yupResolver(schema),
    });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { hasCloseDate, closeDate } = data;
    mutate({
      id: row.id,
      ...data,
      vat: data.vat / 100,
      close_date: hasCloseDate ? closeDate : null,
    });
  };

  useEffect(() => {
    if (!!row) {
      reset({
        name: row.name,
        city_id: row.city.id,
        vat: row.vat,
        hasCloseDate: !!row.close_date,
        closeDate: row.close_date ? row.close_date : "",
      });
    }
  }, [row]);

  //Update branch success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {mode === generalT("add") ? t("addNewBranch") : t("editBranch")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        {mode === "add" ? (
          <InfoBox text={t("canNotAddNewBranch")} />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("branchName")}
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.name}
                    helperText={formState.errors.name?.message}
                  />
                )}
              />{" "}
              <FormControl
                fullWidth
                size="small"
                error={!!formState.errors.city_id}
              >
                <InputLabel id="demo-simple-select-label">
                  {generalT("branchLocation")}
                </InputLabel>
                <Controller
                  name="city_id"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      key={field.value}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={generalT("branchLocation")}
                    >
                      {citiesData?.data?.cities?.map(({ id, name }) => {
                        return (
                          <MenuItem key={id} value={`${id}`}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                />
                <FormHelperText id="my-helper-text">
                  {formState.errors.city_id?.message}
                </FormHelperText>
              </FormControl>
              <Controller
                name="vat"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("vat")}
                    fullWidth
                    size="small"
                    {...field}
                    error={!!formState.errors.vat}
                    helperText={formState.errors.vat?.message}
                  />
                )}
              />
              <Controller
                name="hasCloseDate"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ mt: 1, width: "fit-content" }}
                    control={<Checkbox {...field} color="primary" />}
                    label={t("hasLockDate")}
                    checked={field.value}
                  />
                )}
              />
              {watch("hasCloseDate") && (
                <Controller
                  name="closeDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="date"
                      label={generalT("lockDate")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!formState.errors.closeDate}
                      helperText={formState.errors.closeDate?.message}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              )}
              <Box>
                <InfoBox variant="info" text={generalT("lockDateDescription")} />
              </Box>
            </Stack>
            <Stack mt={3} spacing={2} direction={"row"}>
              <Button
                variant="outlined"
                color="tertiary"
                fullWidth
                onClick={handleClose}
              >
                {generalT("cancel")}
              </Button>
              <LoadingButton
                loading={isPending}
                type="submit"
                variant="contained"
                fullWidth
              >
                {generalT("save")}
              </LoadingButton>
            </Stack>
          </form>
        )}
      </Stack>
    </Modal>
  );
};

export default AddEditBranch;
