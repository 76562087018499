import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileItem from "src/shared/components/file-item";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useEffect } from "react";
import { downloadFileFromUrl, formatBytes } from "src/utils";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useUpdateAuditMutation } from "src/api/auditing/auditing";
import InfoBox from "src/shared/components/info-box";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface IModel {
  open: boolean;
  handleClose: any;
  rowId: number;
  row: any;
  refetch: Function;
}

const schema = yup.object().shape({
  has_issue: yup.boolean().required("Required"),
  note: yup.string().when("has_issue", {
    is: (value: boolean) => value === true,
    then: (value) => value.required("Required"),
  }),
  date: yup.string().required("required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AttachmentsSalesPopup = (props: IModel) => {
  const { open, handleClose, row, refetch } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("operationCost");
  const { t: auditingT } = useTranslation("auditing");
  const { t: generalT } = useTranslation("general");

  //RHF
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<FormInputs>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      has_issue: false,
    },
  });

  // APIS
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateAuditMutation();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });
    const { has_issue, note, date } = data;
    mutateUpdate({
      id: row.id,
      type: row.type,
      date: date,
      status: has_issue ? "issue" : "confirmed",
      has_issue: has_issue,
      ...(note && { note }),
    });
  };

  // const handleConfirm = () => {
  //   mutateUpdate({ id: row.id, type: row.type, status: "confirmed" });
  // };

  useEffect(() => {
    reset({
      has_issue: row.has_issue,
      note: row.note || "",
      date: row.date,
    });
  }, [row]);

  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("confirmed"),
        },
      ]);
      handleClose();
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="attachments"
      aria-describedby="attachments-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 547 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={0}
            mb={1}
          >
            <Typography color={"#101828"} fontSize={"20px"}>
              {t("attachments")}
            </Typography>
            <IconButton onClick={handleClose} sx={{ p: 0 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <>
            {row?.files.map((attachment: any) => {
              return (
                <FileItem
                  key={attachment.id}
                  name={`${attachment.file.name} .${attachment.file.extension}`}
                  info={formatBytes(attachment.file.size)}
                  isDeletable={false}
                  isDownloadable={true}
                  onDownload={() => {
                    downloadFileFromUrl(
                      attachment.file.url,
                      attachment.file.name
                    );
                  }}
                />
              );
            })}
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <TextField
                  type="date"
                  label={`${t("dueDate")} (${t("whenBillDue")})`}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ mt: 3 }}
                  disabled={row.status === "confirmed"}
                />
              )}
            />
            <Box py={1.5}>
              <Typography mb={1}>{auditingT("clientNote")}</Typography>
              <Box mb={1.5}>
                <InfoBox variant="info" text={`${row.app_note || "--"}`} />
              </Box>
              {row.status !== "confirmed" && (
                <Controller
                  name="has_issue"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{ mt: 1 }}
                      control={<Checkbox {...field} color="primary" />}
                      label={t("hasIssue")}
                      checked={field.value}
                    />
                  )}
                />
              )}

              {/* note */}
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("notesOptional")}
                    fullWidth
                    size="small"
                    multiline
                    rows={2}
                    maxRows={3}
                    {...field}
                    error={!!errors.note}
                    helperText={errors.note?.message}
                    sx={{ mt: 1 }}
                    disabled={row.status === "confirmed"}
                  />
                )}
              />
            </Box>
            {row.status !== "confirmed" && (
              <Stack spacing={2} direction={"row"} mt={3}>
                <Button
                  variant="outlined"
                  color="tertiary"
                  fullWidth
                  onClick={handleClose}
                >
                  {generalT("cancel")}
                </Button>
                <LoadingButton
                  type="submit"
                  loading={isPendingUpdate}
                  variant="contained"
                  fullWidth
                >
                  {generalT("confirm")}
                </LoadingButton>
              </Stack>
            )}
          </>
        </form>
      </Stack>
    </Modal>
  );
};

export default AttachmentsSalesPopup;
