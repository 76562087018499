import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Switch } from "@mui/material";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import CirclesIcon from "src/assets/svg-icons/auditing/circles.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useEffect, useState } from "react";
import AttachmentsSalesPopup from "../attachments-popup";
import TypeChangePopup from "../work-on-popup";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useUpdateAuditStatusMutation } from "src/api/auditing/auditing";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import AddOCPopup from "../add-oc-popup";
import AddPurchasePopup from "../add-purchase-popup";
import SecondaryChip from "src/shared/components/secondary-chip";

const AuditingTable = ({
  rows,
  isLoading,
  refetch,
}: {
  rows: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const emptyDataArr = rows?.length === 0;
  const { t } = useTranslation("auditing");
  const { t: generalT } = useTranslation("general");
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const {
    mutate: mutateUpdateStatus,
    status: updateStatusStatus,
    isPending: updateStatusIsPending,
    error: updateStatusError,
  } = useUpdateAuditStatusMutation();

  // const handleOpen = () => setOpen(true);
  const handleOpen = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleOpenConfirm = (row: any) => {
    setSelectedRow(row);
    setOpenConfirm(true);
  };

  const handleOpenAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: any
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(true);
    setSelectedRow(row);
  };
  const handleCloseAttachmentsModal = () =>
    // event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    {
      // event.stopPropagation();
      setOpenAttachmentsModal(false);
    };

  const handleConfirm = () => {
    mutateUpdateStatus({ id: selectedRow.id, status: "progress" });
  };

  const HeadText = [
    generalT("reference"),
    t("relatedReference"),
    generalT("branchName"),
    t("supplierName"),
    t("dueDate"),
    t("auditBy"),
    t("sender"),
    generalT("total"),
    t("payment"),
    generalT("type"),
    t("dateOfTransaction"),
    generalT("status"),
    t("workOn"),
    generalT("actions"),
  ];

  // Update status success and error handling
  useEffect(() => {
    if (updateStatusStatus === "success") {
      refetch();
      handleCloseConfirm();
    } else if (updateStatusStatus === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            updateStatusError?.data.errors[0].value ||
            generalT("somethingWrong"),
        },
      ]);
    }
  }, [updateStatusStatus]);

  return (
    <Box overflow={"auto"} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Stack spacing={3}>
        <TableContainer component={Paper} variant="outlined">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {HeadText.map((item) => (
                  <TableCell
                    key={item}
                    align="center"
                    sx={{ bgcolor: "#F9FAFB" }}
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableLoadingSkeleton rowsLength={5} cellsLength={14} />
            ) : (
              <TableBody>
                {rows?.map((row: any) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center"> {row.reference}</TableCell>
                    <TableCell align="center">
                      {row.related_reference}
                    </TableCell>
                    <TableCell align="center">{row.branch?.name}</TableCell>
                    <TableCell align="center">
                      {row.owner?.name || "--"}
                    </TableCell>
                    <TableCell align="center">{row.date || "--"}</TableCell>
                    <TableCell align="center">
                      {row.audit_by?.name || "--"}
                    </TableCell>
                    <TableCell align="center">
                      {row.created_by?.name || "--"}
                    </TableCell>
                    <TableCell align="center">{row.total ?? "--"}</TableCell>
                    <TableCell align="center">
                      {row?.purchase_event?.from}
                      {row?.purchase_event?.from && row?.payments?.length > 0
                        ? ", "
                        : ""}
                      {row.payments?.map((pay: any, index: number) => {
                        return `${pay.payment_method?.name || ""}${
                          index < row.payments.length - 1 ? ", " : ""
                        }`;
                      })}
                    </TableCell>
                    <TableCell align="center">{t(row.type)}</TableCell>
                    <TableCell align="center">{row.created_at}</TableCell>
                    <TableCell align="center">
                      <Stack>
                        <SecondaryChip
                          variant={
                            row.status === "new"
                              ? "info"
                              : row.status === "confirmed"
                              ? "success"
                              : row.status === "progress"
                              ? "warning"
                              : "error"
                          }
                        />
                      </Stack>
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        color="secondary"
                        checked={row.status !== "new"}
                        onClick={() => handleOpenConfirm(row)}
                        disabled={row.status !== "new"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction={"row"} gap={1.5}>
                        {row.status === "new" && (
                          <Button
                            size="small"
                            variant="outlined"
                            color="tertiary"
                            startIcon={<img src={CirclesIcon} alt="" />}
                            onClick={() => handleOpen(row)}
                            sx={{ borderRadius: "50px" }}
                          >
                            {t("switch")}
                          </Button>
                        )}
                        {(row.status !== "confirmed" ||
                          row.type === "sale" ||
                          row.type === "event") && (
                          <Button
                            color="tertiary"
                            size="small"
                            variant="outlined"
                            onClick={(e) => handleOpenAttachmentsModal(e, row)}
                            startIcon={
                              <VisibilityOutlinedIcon fontSize="small" />
                            }
                          >
                            {generalT("view")}
                          </Button>
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {emptyDataArr && <NoData />}
        </TableContainer>
      </Stack>
      {/* attachments popup */}
      {openAttachmentsModal &&
        (selectedRow?.type === "sale" || selectedRow?.type === "event") && (
          <AttachmentsSalesPopup
            open={openAttachmentsModal}
            handleClose={handleCloseAttachmentsModal}
            rowId={2}
            row={selectedRow}
            refetch={refetch}
          />
        )}
      {/* OC popup */}
      {openAttachmentsModal && selectedRow?.type === "operationCost" && (
        <AddOCPopup
          open={openAttachmentsModal}
          refetch={refetch}
          row={selectedRow}
          handleClose={handleCloseAttachmentsModal}
        />
      )}
      {/* purchase popup */}
      {openAttachmentsModal && selectedRow?.type === "purchase" && (
        <AddPurchasePopup
          open={openAttachmentsModal}
          refetch={refetch}
          row={selectedRow}
          handleClose={handleCloseAttachmentsModal}
        />
      )}
      {/* confirm start working popup */}
      <ConfirmPopup
        open={openConfirm}
        handleClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
        title={t("workOn")}
        subtitle={t("areYouSureToStartWorking")}
        confirmBtnText={generalT("confirm")}
        noIcon
        isPending={updateStatusIsPending}
        confirmBtnColor="primary"
      />
      {/* change type popup */}
      {open && (
        <TypeChangePopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
          id={selectedRow?.id}
          type={selectedRow?.type}
          row={selectedRow}
        />
      )}
    </Box>
  );
};
export default AuditingTable;
