import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import InventoryIcon from "src/assets/svg-icons/side-bar/inventory.svg";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import InfoItem from "./info-item";
import { useNavigate, useParams } from "react-router-dom";
import FileItem from "src/shared/components/file-item";
import { downloadFileFromUrl, formatBytes } from "src/utils";
import { useTranslation } from "react-i18next";
import TransactionItemsTable from "./table";
import {
  useGetTransferById,
  useUpdateTransferMutation,
} from "src/api/inventory/transfer";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { ConfirmPopup } from "src/shared/components/confirm-popup";

const TransferTransactionDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("inventory");
  const { t: purchasingT } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [openConfirm, setOpenConfirm] = useState(false);

  // APIs
  const { data, isLoading, refetch } = useGetTransferById({ id: id || "" });
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateTransferMutation();

  const {
    reference,
    from_branch,
    to_branch,
    total,
    note,
    status,
    received_at,
    sent_at,
    transfer_items,
    id: transferId,
  } = data?.data || {};

  const navigateToInventory = () => {
    navigate("/inventory/transfer");
  };

  const handleCloseConfirm = () => setOpenConfirm(false);
  const handleOpenConfirm = (row: any) => setOpenConfirm(true);

  const handleConfirm = () => {
    mutateUpdate({
      id: transferId,
      status: "received",
    });
  };

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
      handleCloseConfirm();
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={InventoryIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {purchasingT("transactionDetails")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToInventory}
          sx={{ cursor: "pointer" }}
        >
          {t("inventory")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          sx={{ cursor: "pointer" }}
        >
          {purchasingT("transactionDetails")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {reference}
          </Typography>
        </Box>
      </Stack>
      {isLoading && !data ? (
        <Box display={"flex"} justifyContent={"center"} pt={20}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack bgcolor={"#F9FAFB"} spacing={1.5} p={2}>
            <InfoItem label={t("from")} value={from_branch?.name} />
            <Grid container>
              <Grid item sm={6}>
                <InfoItem label={t("to")} value={to_branch?.name} />
              </Grid>
              <Grid item sm={6}>
                <InfoItem label={t("dateSend")} value={sent_at} />
              </Grid>
              <Grid item sm={6} paddingY={1.5}>
                <InfoItem label={t("total")} value={total.toFixed(3)} />
              </Grid>
              <Grid item sm={6} paddingY={1.5}>
                <InfoItem
                  label={purchasingT("status")}
                  value={t(status === "received" ? "confirmed" : status)}
                />
              </Grid>
              <Grid item sm={6}>
                <InfoItem label={generalT("notes")} value={note || ""} />
              </Grid>
              <Grid item sm={6}>
                <InfoItem label={t("dateReceived")} value={received_at} />
              </Grid>
              <Grid item sm={6} paddingTop={1.5}>
                <InfoItem label={t("attachedBill")} value={""} />
              </Grid>
            </Grid>

            <Stack gap={3} direction={"row"} maxWidth={"50%"} flexWrap={"wrap"}>
              {data?.data.transfer_files.map((attachment: any) => {
                return (
                  <FileItem
                    isDeletable={false}
                    key={attachment.id}
                    name={`${attachment.file.name} .${attachment.file.extension}`}
                    info={formatBytes(attachment.file.size)}
                    isDownloadable
                    onDownload={() => {
                      downloadFileFromUrl(
                        attachment.file.url,
                        attachment.file.name
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>

          <TransactionItemsTable items={transfer_items || []} />

          {status === "sent" && (
            <Stack alignItems={"flex-end"}>
              <LoadingButton
                variant="contained"
                sx={{ minWidth: "200px" }}
                onClick={handleOpenConfirm}
                loading={isPendingUpdate}
              >
                {generalT("confirm")}
              </LoadingButton>
            </Stack>
          )}
        </>
      )}

      {/* confirm popup */}
      <ConfirmPopup
        open={openConfirm}
        handleClose={handleCloseConfirm}
        handleConfirm={handleConfirm}
        title={generalT("confirm")}
        subtitle={generalT("areYouSureToConfirm")}
        confirmBtnText={generalT("confirm")}
        noIcon
        isPending={isPendingUpdate}
        confirmBtnColor={"primary"}
      />
    </Stack>
  );
};

export default TransferTransactionDetails;
