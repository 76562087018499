import { Box, Link, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const SideBarItem = ({
  icon,
  label,
  path,
}: {
  icon?: any;
  label: string;
  path: string;
}) => {
  const { pathname } = useLocation();
  const isIncludingPath = pathname.includes(path);
  const { t } = useTranslation("general");

  return (
    <Link
      color={"secondary"}
      component={RouterLink}
      underline="none"
      to={path}
      fontSize={"14px"}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        sx={{ cursor: "pointer", "&:hover": { backgroundColor: "#FFF8F4" } }}
        bgcolor={isIncludingPath ? "#FFF8F4" : ""}
      >
        <Box
          bgcolor={isIncludingPath ? "primary.main" : ""}
          height={"40px"}
          width={"6px"}
          borderRadius={"0px 4px 4px 0px"}
        />
        <Stack direction={"row"} py={"12px"} px={"28px"} gap={1.5}>
          <SvgIcon color={isIncludingPath ? "primary" : "action"}>
            {icon}
          </SvgIcon>
          <Typography
            fontWeight={isIncludingPath ? 600 : 400}
            color={isIncludingPath ? "primary" : "black"}
          >
            {t(label)}
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export default SideBarItem;
