import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeleteOCTransactionMutation } from "src/api/operation-cost/transactions";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import AddEditTransaction from "../../add-edit-transaction-popup";
import AttachmentsPopup from "../../attachments-popup";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import AddPaymentPopup from "../../add-payment-popup";
import { useTranslation } from "react-i18next";

interface IAction {
  row: any;
  refetch: Function;
  isDeprecationInvoice: boolean;
}

const TableActions = (props: IAction) => {
  const navigate = useNavigate();
  const { row, refetch, isDeprecationInvoice } = props;
  const { id, reference } = row;
  const { t: generalT } = useTranslation("general");
  const { t } = useTranslation("operationCost");

  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);

  // APIS
  const { mutate, isPending, status, error } = useDeleteOCTransactionMutation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(true);
  };
  const handleCloseAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(false);
  };

  const handleDelete = () => {
    if (row.account?.type === "asset" && row.sub_invoices?.length > 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("couldNotBeDeletedAsItHasSubInvoices"),
        },
      ]);
      return;
    }
    mutate(id);
  };

  const navigateToTransDetails = () => {
    navigate(`/operation/transaction/${id}`);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} width={"100%"}>
      <Tooltip title={generalT("edit")}>
        <IconButton onClick={handleOpenEdit}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={generalT("delete")}>
        <IconButton onClick={handleOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={generalT("attachments")}>
        <IconButton onClick={handleOpenAttachmentsModal}>
          <img src={DownloadIcon} alt="" />
        </IconButton>
      </Tooltip>

      <Tooltip title={generalT("details")} onClick={navigateToTransDetails}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      {row?.status !== "Paid" && !isDeprecationInvoice && (
        <Tooltip title={generalT("pay")}>
          <Button
            color="tertiary"
            size="small"
            variant="outlined"
            onClick={handleOpenPay}
          >
            {`${generalT("pay")}!`}
          </Button>
        </Tooltip>
      )}

      {/* payment popup */}
      {openPay && (
        <AddPaymentPopup
          open={openPay}
          handleClose={handleClosePay}
          refetch={refetch}
          id={id}
          row={row}
        />
      )}
      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={reference}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {/* edit popup */}
      {openEdit && (
        <AddEditTransaction
          open={openEdit}
          handleClose={handleCloseEdit}
          row={row}
          refetch={refetch}
        />
      )}
      {/* attachments popup */}
      {openAttachmentsModal && (
        <AttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={row.id}
        />
      )}
    </Stack>
  );
};
export default TableActions;
