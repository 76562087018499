import { atom } from "recoil";
import { ConstLocalStorage } from "src/constants/_localStorage";
import {
  getLocalStoredData,
  setLocalStoredData,
} from "src/utils/_localStorage";

export const authState = atom({
  key: "auth", // unique ID
  default: {
    token: getLocalStoredData(ConstLocalStorage.token),
    user: getLocalStoredData(ConstLocalStorage.user),
    // permissions: {

    // }
  },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((data) => {
        if (data.token) {
          setLocalStoredData(ConstLocalStorage.token, data.token);
        }
        if (data.user) {
          setLocalStoredData(ConstLocalStorage.user, data.user);
        }
      });
    },
  ],
});
