import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const TransactionItemsTable = ({ items }: { items: any[] }) => {
  const { t } = useTranslation("inventory");
  const { t: purchasingT } = useTranslation("purchasing");

  const tableHeadText = [
    purchasingT("name"),
    t("sku"),
    purchasingT("unit"),
    t("quantity"),
    t("total"),
  ];
  
  const emptyDataArr = items.length === 0;

  return (
    <Box>
      <Typography mb={1}>{t("items")}</Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {false ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {items.map((row: any) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.item.name}</TableCell>
                  <TableCell align="center">{row.item.sku}</TableCell>
                  <TableCell align="center">{row.item.unit.name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default TransactionItemsTable;
