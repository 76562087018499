import { Stack } from "@mui/material";
import TransferTable from "./table";

const Transfer = () => {
  return (
    <Stack spacing={1.5}>
      <TransferTable />
    </Stack>
  );
};

export default Transfer;
