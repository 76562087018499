import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useTranslation } from "react-i18next";

interface IProps {
  open: boolean;
  handleClose: any;
  refNum: string;
  setRefNum: Function;
  dueDate: string;
  setDueDate: Function;
  status: string;
  setStatus: Function;
  type: string;
  setType: Function;
}
const FilterDrawer = (props: IProps) => {
  const {
    open,
    handleClose,
    refNum,
    setRefNum,
    status,
    setStatus,
    type,
    setType,
    dueDate,
    setDueDate,
  } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const { t: auditingT } = useTranslation("auditing");

  const handleClearFilters = () => {
    handleClose();
    setRefNum("");
    setDueDate();
    setStatus("all");
    setType("all");
  };

  const types = [
    {
      label: generalT("all"),
      value: "all",
    },
    {
      label: auditingT("sale"),
      value: "sale",
    },
    {
      label: auditingT("operationCost"),
      value: "operationCost",
    },
    {
      label: auditingT("purchase"),
      value: "purchase",
    },
    {
      label: t("event"),
      value: "event",
    },
  ];

  const statusTypes = [
    {
      label: generalT("all"),
      value: "all",
    },
    {
      label: auditingT("new"),
      value: "new",
    },
    {
      label: auditingT("progress"),
      value: "progress",
    },
    {
      label: auditingT("issue"),
      value: "issue",
    },
    {
      label: auditingT("confirmed"),
      value: "confirmed",
    },
  ];

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {generalT("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          <TextField
            label={t("reference")}
            variant="outlined"
            size="small"
            fullWidth
            value={refNum}
            onChange={(e) => setRefNum(e.target.value)}
          />
          <TextField
            type="date"
            label={t("dueDate")}
            variant="outlined"
            size="small"
            fullWidth
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/*  type */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("type")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={type}
              onChange={(e) => setType(e.target.value)}
              input={<OutlinedInput label={t("type")} />}
            >
              {types.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/*  status */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("status")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              input={<OutlinedInput label={t("status")} />}
            >
              {statusTypes.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleClose}>
            {generalT("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {generalT("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
